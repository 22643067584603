<template>
    <div class="settings">
        <van-nav-bar class="bar" :border="false" :title="$t('user.withdraw_funds')" left-arrow @click-left="onClickLeft" />
        <div class="container">
            <p class="mb-12">{{ $t('withdraw.current_account_balance') }}：<span class="balance-text">{{ (user.balance || '0.00') + $t('币单位')}}</span></p>
            <van-field class="settings-cell mb-12" v-model="num" :placeholder="$t('withdraw.placeholder1')" />
            <p class="mb-12">{{ $t('withdraw.bank_card_info') }}：
                <router-link class="h5-link" style="color: #07c160;text-decoration: underline;" to="/bank_card" v-if="!bankCard">{{ $t('withdraw.tip1') }} —></router-link>
                <router-link class="pc-link" style="color: #07c160;text-decoration: underline;" to="/my/bank_card" v-if="!bankCard">{{ $t('withdraw.tip1') }} —></router-link>
            </p>
            <van-cell-group class="bank-info" v-if="bankCard">
                <p>{{ $t('withdraw.receiving_bank') }}：{{ bankCard?.bank_name || '--' }}</p>
                <p>{{ $t('withdraw.receiving_account') }}：{{ bankCard?.card_number || '--' }}</p>
                <p>{{ $t('withdraw.receiving_name') }}：{{ bankCard?.card_name || '--' }}</p>
            </van-cell-group>
            <p class="mb-12">{{ $t('user.withdraw_password') }}：</p>
            <van-field class="settings-cell" type="password" v-model="pay_password" :placeholder="$t('withdraw.placeholder2')" />
            <div style="height: 24px;"></div>
            <van-button class="submit-btn" type="primary" color="linear-gradient( 270deg, #A44AFB 0%, #F85DC6 100%)" round block @click="onSubmit" :loading="loading">{{ $t('withdraw.submit_application') }}</van-button>
            <div class="withdraw-tips">
                <p>{{ $t('withdraw.hint') }}：</p>
                <p>{{ $t('withdraw.withdraw_time') }}：11:00 - 23:00
                    <van-icon name="phone-circle-o" />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { getBankCard, withdraw } from '@/api'
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            num: '',
            pay_password: '',
            bankCard: null,
            loading: false,
        };
    },
    mounted() {
        this._getBankCard()
        if (!this.user.has_pay_password) {
            this.$dialog.confirm({
                title: this.$t('withdraw.hint'),
                message: this.$t('withdraw.tip2'),
                confirmButtonText: this.$t('确认'),
                cancelButtonText: this.$t('取消'),
            }).then(() => {
                this.$router.push('/cash_password');
            }).catch(() => {
                this.$router.back();
            });
        }
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
        ...mapActions(['fetchUser']),
        _getBankCard() {
            getBankCard().then(res => {
                this.bankCard = res.data;
            })
        },
        onClickLeft() {
            this.$router.back();
        },
        onSubmit() {
            if (!this.num) {
                this.$toast(this.$t('withdraw.placeholder1'));
                return;
            }
            if (!this.pay_password) {
                this.$toast(this.$t('withdraw.placeholder2'));
                return;
            }
            if (this.pay_password.length !== 6) {
                this.$toast(this.$t('withdraw.toast1'));
                return;
            }
            if (!this.bankCard) {
                this.$toast(this.$t('withdraw.toast2'));
                return;
            }
            this.loading = true;
            withdraw({
                amount: this.num,
                bank_id: this.bankCard.id,
                pay_password: this.pay_password,
            }).then(({ code, message }) => {
                this.loading = false;
                this.$toast(message);
                if (code === 200) {
                    this.fetchUser();
                    setTimeout(() => {
                        this.$router.back();
                    }, 1500)
                }
            }).catch(() => {
                this.loading = false;
            })
        },
    },
};
</script>

<style lang="less" scoped>
.bank-info {
    padding: 12px;
    margin-bottom: 12px;

    > p {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.withdraw-tips {
    margin-top: 24px;
}

.balance-text {
    color: #ee0a24;
}
.settings {
    min-height: 100vh;
    background: url(../../../assets/login_bg.png) left top no-repeat #fff;
    background-size: 100%;
    .bar {
        background: none;
    }
    .settings-cell {
        width: 100%;
        height: 48px;
        background: #ffffff;
        border-radius: 23px 23px 23px 23px;
    }
}
.bar /deep/ .van-nav-bar__left .van-icon {
    color: #333333;
}
.pc-link {
    display: none;
}
@media only screen and (min-width: 750px) {
    .h5-link {
        display: none;
    }
    .pc-link {
        display: inline;
    }
    .settings {
        background: none;
        padding: 42px;
        .bar {
            line-height: 30px;
            z-index: 0;
            /deep/ .van-nav-bar__content {
                height: 30px;
            }
            /deep/ .van-nav-bar__left {
                padding: 0;
                .van-icon {
                    font-size: 28px;
                }
            }
            /deep/ .van-nav-bar__title {
                font-size: 28px;
            }
        }
        .settings-cell {
            height: 78px;
            line-height: 78px;
            background: #f0f3f8;
            border-radius: 16px;
            padding: 0 20px;
            font-size: 18px;
        }
        .mb-12 {
            font-size: 20px;
            color: #333333;
            margin-bottom: 16px;
        }
        .bank-info {
            padding: 12px;
            margin-bottom: 12px;

            > p {
                margin-bottom: 12px;
                font-size: 18px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .submit-btn {
            width: 319px;
            height: 60px;
            line-height: 60px;
            background: linear-gradient(270deg, #a44afb 0%, #f85dc6 100%);
            border-radius: 55px 55px 55px 55px;
            font-size: 16px;
            margin: 0 auto;
        }
        .withdraw-tips {
            margin-top: 24px;
            font-size: 18px;
        }
    }
}
</style>
